import { Svg, SvgProps } from '@pancakeswap/uikit'

const GradientLogo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      viewBox="0 0 100 100"
      fill="none"
      {...props}
    >
      <path
      d="M50 0C22.4031 0 0 22.4031 0 50C0 77.5969 22.4031 100 50 100C77.5969 100 100 77.5969 100 50C100 22.4031 77.5969 0 50 0ZM50.3101 87.5969C40.814 87.6744 32.093 84.2248 25.4651 78.4496C25.155 78.1783 25.3876 77.7132 25.7752 77.7907C37.9845 80.2326 63.3333 81.2791 78.5659 53.5271C78.6822 53.3333 78.4109 53.1395 78.2558 53.2946C73.4884 58.9147 55.6977 77.3256 31.7442 70.3488C3.60465 62.1705 13.0233 36.6667 32.907 44.5736C49.9225 51.3566 59.9612 54.031 69.6899 49.7674C70.1163 49.5736 69.9225 48.9922 69.4961 49.031C64.6124 49.6124 53.7209 49.5349 41.124 40.3488C31.3953 33.3333 23.1008 32.5969 17.7132 33.2558C17.093 33.3333 16.6279 32.6744 16.938 32.1318C23.2946 20.3876 35.6977 12.4031 50 12.4031C55.3876 12.4031 60.5039 13.5271 65.155 15.5814C65.7364 15.8527 80.6977 23.6047 76.2403 32.1318C71.8605 40.4651 52.8295 25.5426 36.2791 27.5581C35.8527 27.5969 35.814 28.2171 36.2403 28.3333C39.5349 29.1473 46.6279 31.3953 56.2403 37.4806C67.7519 44.7287 77.2481 45.6977 84.6124 38.4884C85.0388 38.062 85.7364 38.2558 85.8915 38.7985C87.0543 42.5581 87.6744 46.5891 87.5969 50.7752C87.2093 71.0078 70.5426 87.4419 50.3101 87.5969Z"
      fill="url(#paint0_linear_596_262)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_596_262"
        x1={10.181}
        y1={19.7557}
        x2={89.819}
        y2={80.2443}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1746} stopColor="#07AFFE" />
        <stop offset={0.8783} stopColor="#BEC9D1" />
      </linearGradient>
    </defs>
    </Svg>
  )
}

export default GradientLogo
